import React, { useState, forwardRef } from "react"
import { Link } from "gatsby"
import { Pie } from "react-chartjs-2"
import { FiHelpCircle } from "@react-icons/all-files/fi/FiHelpCircle"
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

import Layout from "../components/layout"
import SEO from "../components/seo"
import { progressPercentage } from "../../node_modules/style-value-types";

const initialData = {
    labels: ['Grams of Protein', 'Grams of Fat', 'Grams of Carbs'],
    datasets: [
        {
            label: 'My Macros',
            data: [],
            backgroundColor: ['#e63946', '#2a9d8f', '#e9c46a'],
        }
    ]
};

const initOptions = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'My Macros'
        },
        subtitle: {
            display: true,
            text: 'Total Calories:'
        },
        tooltips: {
            enabled: false
        }
    },
};

const tdStyle = {
    width: '200px'
}

const MacrosPage = () => {
    const [data, setData] = useState(initialData);
    const [options, setOptions] = useState(initOptions);
    let debounce = null;

    const ProteinHelp = forwardRef((props, ref) => <span ref={ref}><FiHelpCircle /></span>);
    const FatHelp = forwardRef((props, ref) => <span ref={ref}><FiHelpCircle /></span>);
    const MaintHelp = forwardRef((props, ref) => <span ref={ref}><FiHelpCircle /></span>);

    const calcMacros = () => {
        const SUBTITLE_PREFIX = 'Total Calories:';
        const CALS_PER_GRAM_OF_PROTEIN = 4;
        const CALS_PER_GRAM_OF_FAT = 9;
        const CALS_PER_GRAM_OF_CARBS = 4;
    
        let bw = document.querySelector('input[name="weight"]').value;
        let deficit = document.querySelector('input[name="deficit"]').value;
    
        let proteinMod = document.querySelector('select[name="protein"]').value;
        let protein = bw * proteinMod; // grams
        let proteinCalories = protein * CALS_PER_GRAM_OF_PROTEIN;
    
        let fatMod = document.querySelector('select[name="fat"]').value;
        let fat = bw * fatMod; // grams
        let fatCalories = fat * CALS_PER_GRAM_OF_FAT;
    
        let maintCaloriesMod = document.querySelector('select[name="maintCalories"]').value;
        let maintCalories = bw * maintCaloriesMod;
    
        let caloricDeficit = maintCalories - deficit;
        let carbCalories = caloricDeficit - (proteinCalories + fatCalories);
        let carbs = carbCalories / CALS_PER_GRAM_OF_CARBS;
    
        let totalCalories = proteinCalories + fatCalories + carbCalories;

        if (debounce && debounce > 0) {
            clearTimeout(debounce);
        }
        debounce = setTimeout(() => {
            setData({
                labels: [protein + 'g Protein', fat + 'g Fat', carbs + 'g Carbs'],
                datasets: [
                    {
                        label: 'My Macros',
                        data: [protein, fat, carbs],
                        backgroundColor: ['#e63946', '#2a9d8f', '#1d3557'],
                    }
                ]
            });
    
            initOptions.plugins.subtitle.text = SUBTITLE_PREFIX + totalCalories;
            setOptions(initOptions);
        }, 350);
    }

    return (
        <Layout>
            <SEO title="Fitness Macros" />
            <h1>Fitness Macros</h1>

            <table style={{width: '500px'}}>
                <tbody>
                    <tr>
                        <td style={tdStyle}>
                            <label htmlFor="weight">
                                Body Weight    
                            </label>
                        </td>
                        <td>
                            <input type="number" name="weight" onInput={calcMacros} />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label htmlFor="deficit">
                                Caloric Deficit 
                            </label>
                        </td>
                        <td>
                            <input type="number" name="deficit" onInput={calcMacros} />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label htmlFor="protein">
                                Protein
                            </label>
                        </td>
                        <td>
                            <select name="protein" onInput={calcMacros}>
                                <option value="0.8">0.8</option>
                                <option value="0.9">0.9</option>
                                <option value="1.0">1.0</option>
                                <option value="1.1">1.1</option>
                            </select>
                            &nbsp;
                            <Tippy content="0.8 is higher body-fat, less active and 1.1 is getting ready htmlFor competition">
                                <ProteinHelp />
                            </Tippy>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label htmlFor="fat">Fat</label>
                        </td>
                        <td>
                            <select name="fat" onInput={calcMacros}>
                                <option value="0.3">0.3</option>
                                <option value="0.4">0.4</option>
                            </select>
                            &nbsp;
                            <Tippy content="0.3 is if you like more carbs in your diet or 0.4 is if you like more fat in your diet">
                                <FatHelp />
                            </Tippy>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label htmlFor="maintCalories">Maintenance Calories</label>
                        </td>
                        <td>
                            <select name="maintCalories" onInput={calcMacros}>
                                <option value="14">14</option>
                                <option value="15">15</option>
                                <option value="16">16</option>
                            </select>
                            &nbsp;
                            <Tippy content='Carbs are determined by "maintenance calories". 14 is less active, 16 is more active'>
                                <MaintHelp />
                            </Tippy>
                        </td>
                    </tr>
                </tbody>
            </table>

            <Pie id="pieChart" data={data} options={options} />

            <code>
                Maintenance calories: BW X 14 - 16 <br />
                Protein: BW x 0.8 - 1.1 <br />
                Fat: BW x 0.3 - 0.4 <br />
                Carbs: MAINTENANCE - 500 CALORIES = DEFICIT CALORIES <br />
                            PROTEIN x 4 = X CALORIES <br />
                            FAT x 9 = X CALORIES <br />
                DEFICIT CALORIES - (PROTEIN + FAT CALORIES) = X <br />
                CARBS = X/4 <br />
            </code>
            <br />
            <br />
        </Layout>
    )
}

export default MacrosPage
